<template>
    <div>
        <v-card :loading="loading" class="mx-auto" outlined>
            <template slot="progress">
                <v-progress-linear
                    :color="$globalSettings.progressbarClasses"
                    height="10"
                    indeterminate
                ></v-progress-linear>
            </template>

            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="12">
                        <div v-if="currentView === 'list'">
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="navigationBack"><v-icon small>fa-angle-left</v-icon></v-btn> <span class="secondary-text">Reports</span>
                        </div>
                        <div v-if="currentView === 'report_applications'">
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="showReportsList"><v-icon small>fa-angle-left</v-icon></v-btn> <span class="secondary-text">Applications Report</span>
                            <ExportReport report-name="Applications Report" target-element="report_applications" class="float-right" :disabled="loading"></ExportReport>
                        </div>
                        <div v-if="currentView === 'report_users'">
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="showReportsList"><v-icon small>fa-angle-left</v-icon></v-btn> <span class="secondary-text">Users Report</span>
                            <ExportReport report-name="Users Report" target-element="report_users" class="float-right" :disabled="loading"></ExportReport>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row v-if="currentView === 'list'">
                    <v-col cols="12" sm="4">
                        <v-card class="mx-auto" outlined>
                            <v-card-title>Applications Report</v-card-title>
                            <v-card-text>
                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="report_applications.startDate && $globalHelpers.getFormattedDate(report_applications.startDate, '', 'MMMM D, YYYY')" label="From" readonly v-bind="attrs" v-on="on" clearable @click:clear="report_applications.startDate = null" prepend-icon="fa-regular fa-calendar"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report_applications.startDate"></v-date-picker>
                                </v-menu>
                                <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="report_applications.endDate && $globalHelpers.getFormattedDate(report_applications.endDate, '', 'MMMM D, YYYY')" label="To" readonly v-bind="attrs" v-on="on" clearable @click:clear="report_applications.endDate = null" prepend-icon="fa-regular fa-calendar"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report_applications.endDate"></v-date-picker>
                                </v-menu>

                                <v-select :items="applicationTypes" item-value="id" item-text="label" label="Application Type" v-model="report_applications.applicationTypeIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_applications.applicationTypeIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_applications.applicationTypeIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ report_applications.applicationTypeIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>

                                <v-select :items="statuses" item-value="id" item-text="label" label="Application Status" v-model="report_applications.statusIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_applications.statusIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_applications.statusIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ report_applications.statusIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>

                                <v-select :items="serviceOffices" item-value="id" item-text="label" label="Service Office" v-model="report_applications.serviceOfficeIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_applications.serviceOfficeIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_applications.serviceOfficeIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ report_applications.serviceOfficeIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>

                                <v-select :items="report_application_datas.exporterTypes" item-value="id" item-text="label" label="Department" v-model="report_applications.exporterTypeId" @change="reportApplicationsModifyFilters()"></v-select>

                                <!--<v-select :items="exporterTypes" item-value="id" item-text="label" label="Department" v-model="report_applications.exporterTypeIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_applications.exporterTypeIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_applications.exporterTypeIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey&#45;&#45;text text-caption">(+{{ report_applications.exporterTypeIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>-->

                                <v-select :items="report_application_datas.factoryTypes" item-value="Id" item-text="Label" label="Factory Type" v-model="report_applications.factoryTypeId"></v-select>

                                <!--<v-select :items="factoryTypes" item-value="Id" item-text="Label" label="Factory Type" v-model="report_applications.factoryTypeIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_applications.factoryTypeIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_applications.factoryTypeIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey&#45;&#45;text text-caption">(+{{ report_applications.factoryTypeIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>-->

                            </v-card-text>
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-btn class="float-end" color="primary" @click="generate('report_applications')">Generate</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>

                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card class="mx-auto" outlined>
                            <v-card-title>Users Report</v-card-title>
                            <v-card-text>
                                <v-select :items="report_user_datas.userTypes" item-value="id" item-text="label" label="Type" v-model="report_users.type" @change="reportUsersModifyFilters"></v-select>

                                <v-select :items="serviceOffices" item-value="id" item-text="label" label="Service Office" v-model="report_users.serviceOfficeIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_users.serviceOfficeIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_users.serviceOfficeIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ report_users.serviceOfficeIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>

                                <v-select :items="report_users.roles" item-value="id" item-text="label" label="Role" v-model="report_users.roleIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_users.roleIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_users.roleIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ report_users.roleIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>

                                <v-select :items="report_user_datas.exporterTypes" item-value="id" item-text="label" label="Department" v-model="report_users.exporterTypeIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_users.exporterTypeIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_users.exporterTypeIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ report_users.exporterTypeIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>

                                <v-select :items="report_user_datas.designations" item-value="id" item-text="label" label="Designation" v-model="report_users.designationIds" multiple small-chips>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="toggleSelection('report_users.designationIds')">
                                            <v-list-item-action>
                                                <v-icon :color="report_users.designationIds.length > 0 ? 'indigo darken-4' : ''">fa-regular fa-square-check</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0" small><span>{{ item.label }}</span></v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">(+{{ report_users.designationIds.length - 1 }} others)</span>
                                    </template>
                                </v-select>

                            </v-card-text>
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-btn class="float-end" color="primary" @click="generate('report_users')" :disabled="report_users.type === null">Generate</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="currentView === 'report_applications'">
                    <v-col cols="12" sm="12">
                        <table cellspacing="0" border="1" width="100%" class="report_table" id="report_applications">
                            <tbody>
                            <tr><td colspan="11">Report: <span class="font-bold">Applications Report</span></td></tr>
                            <tr>
                                <td class="text-left font-bold">From</td>
                                <td class="text-left font-bold">To</td>
                                <td class="text-left font-bold" colspan="2">Type</td>
                                <td class="text-left font-bold">Service Office</td>
                                <td class="text-left font-bold" colspan="2">Department</td>
                                <td class="text-left font-bold" colspan="2">Factory Type</td>
                                <td class="text-left font-bold" colspan="2">Status</td>
                            </tr>
                            <tr>
                                <td>
                                    {{ $globalHelpers.getFormattedDate(report_applications.startDate, '', 'DD/MM/YYYY') }}
                                </td>
                                <td>
                                    {{ $globalHelpers.getFormattedDate(report_applications.endDate, '', 'DD/MM/YYYY') }}
                                </td>
                                <td colspan="2">
                                    <div v-if="report_applications.applicationTypeIds.length > 0">
                                        <div v-for="(item, i) in report_applications.applicationTypeIds" :key="i">{{ $globalHelpers.humanize(applicationTypes.find(x => x.id === item).label) }}{{ report_applications.applicationTypeIds.length > i + 1? ', ': '' }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td>
                                    <div v-if="report_applications.serviceOfficeIds.length > 0">
                                        <div v-for="(item, i) in report_applications.serviceOfficeIds" :key="i">{{ $globalHelpers.humanize(serviceOffices.find(x => x.id === item).label) }}{{ report_applications.serviceOfficeIds.length > i + 1? ', ': '' }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td colspan="2">
                                    <div v-if="report_applications.exporterTypeId">
                                         {{$globalHelpers.humanize(exporterTypes.find(x => x.id === report_applications.exporterTypeId).label) }}
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td colspan="2">
                                    <div v-if="report_applications.factoryTypeId">
                                        {{ factoryTypes.find(x => x.Id === report_applications.factoryTypeId).Label }}
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td colspan="2">
                                    <div v-if="report_applications.statusIds.length > 0">
                                        <div v-for="(item, i) in report_applications.statusIds" :key="i">{{ $globalHelpers.humanize(statuses.find(x => x.id === item).label) }}{{ report_applications.statusIds.length > i + 1? ', ': '' }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                            </tr>
                            <tr><td colspan="11"></td></tr>
                            <tr>
                                <td class="text-left font-bold">App. ID</td>
                                <td class="text-left font-bold">Department</td>
                                <td class="text-left font-bold">Organization</td>
                                <td class="text-left font-bold">Reg. No.</td>
                                <td class="text-left font-bold">Type</td>
                                <td class="text-left font-bold">Status</td>
                                <td class="text-left font-bold">Factory Type</td>
                                <td class="text-left font-bold">Offc. Addr.</td>
                                <td class="text-left font-bold">Offc. Phone</td>
                                <td class="text-left font-bold">Service Office</td>
                                <td class="text-left font-bold">Valid Till</td>
                            </tr>
                            <tr v-for="(item, i) in report_applications.list" :key="i">
                                <td>{{ item.Id }}</td>
                                <td>{{ $globalHelpers.humanize(exporterTypes.find(x => x.id === item.User.ExporterType).label) }}</td>
                                <td>{{ item.User.OrganizationName }}</td>
                                <td>{{ item.User.Username }}</td>
                                <td>{{ $globalHelpers.humanize(applicationTypes.find(x => x.id === item.ApplicationType).label) }}</td>
                                <td>{{ $globalHelpers.humanize(statuses.find(x => x.id === item.StatusId).label) }}</td>
                                <td>
                                    <span v-if="item.EAInspections.length > 0">
                                        <span v-if="item.EAInspections[0].EAInspectionFactoryTypes.length > 0">
                                            <span v-for="(eaInspectionFactoryType, i) in item.EAInspections[0].EAInspectionFactoryTypes" :key="i" class="text-underline pdf-margin-right-2">
                                                {{ eaInspectionFactoryType.FactoryType.Label }}
                                                <span v-if="i < item.EAInspections[0].EAInspectionFactoryTypes.length - 1">, &nbsp;</span>
                                            </span>
                                        </span>
                                    </span>
                                </td>
                                <td>{{ item.OfficeAddress }}</td>
                                <td>{{ item.OfficePhone }}</td>
                                <td>{{ $globalHelpers.humanize(serviceOffices.find(x => x.id === item.ServiceOfficeId).label)  }}</td>
                                <td><div v-if="item.ApplicationType !== $globalEnums.ApplicationTypes.Address_Change && item.StatusId === $globalEnums.ApplicationStatuses.Approved">{{ $globalHelpers.getFormattedDate(item.ValidTillDate, '', 'DD/MM/YYYY') }}</div></td>
                            </tr>
                            <tr>
                                <th colspan="11"></th>
                            </tr>
                            <tr>
                                <td>Total:</td>
                                <td>{{ report_applications.count }}</td>
                                <td colspan="9"></td>
                            </tr>
                            </tbody>
                        </table>
                    </v-col>
                </v-row>

                <v-row v-if="currentView === 'report_users'">
                    <v-col cols="12" sm="12">
                        <table cellspacing="0" border="1" width="100%" class="report_table" id="report_users">
                            <tbody>
                            <tr><td colspan="10">Report: <span class="font-bold">Users Report</span></td></tr>
                            <tr>
                                <td class="text-left font-bold" colspan="2">Type</td>
                                <td class="text-left font-bold" colspan="2">Service Office</td>
                                <td class="text-left font-bold" colspan="2">Role</td>
                                <td class="text-left font-bold" colspan="2">Department</td>
                                <td class="text-left font-bold" colspan="2">Designation</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div v-if="report_users.type !== null">
                                        <div>{{ report_user_datas.userTypes.find(x => x.id === report_users.type).label }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td colspan="2">
                                    <div v-if="report_users.serviceOfficeIds.length > 0">
                                        <div v-for="(item, i) in report_users.serviceOfficeIds" :key="i">{{ $globalHelpers.humanize(serviceOffices.find(x => x.id === item).label) }}{{ report_users.serviceOfficeIds.length > i + 1? ', ': '' }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td colspan="2">
                                    <div v-if="report_users.roleIds.length > 0">
                                        <div v-for="(item, i) in report_users.roleIds" :key="i">{{ $globalHelpers.humanize(roles.find(x => x.id === item).label) }}{{ report_users.roleIds.length > i + 1? ', ': '' }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td colspan="2">
                                    <div v-if="report_users.exporterTypeIds.length > 0">
                                        <div v-for="(item, i) in report_users.exporterTypeIds" :key="i">{{ $globalHelpers.humanize(exporterTypes.find(x => x.id === item).label) }}{{ report_users.exporterTypeIds.length > i + 1? ', ': '' }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                                <td colspan="2">
                                    <div v-if="report_users.designationIds.length > 0">
                                        <div v-for="(item, i) in report_users.designationIds" :key="i">{{ $globalHelpers.humanize(designations.find(x => x.id === item).label) }}{{ report_users.designationIds.length > i + 1? ', ': '' }}</div>
                                    </div>
                                    <div v-else>All</div>
                                </td>
                            </tr>
                            <tr><td colspan="10"></td></tr>
                            <tr>
                                <td class="text-left font-bold">Email</td>
                                <td class="text-left font-bold">Name</td>
                                <td class="text-left font-bold">Designation</td>
                                <td class="text-left font-bold">Organization</td>
                                <td class="text-left font-bold">Username</td>
                                <td class="text-left font-bold">Mobile</td>
                                <td class="text-left font-bold">Department</td>
                                <td class="text-left font-bold">Role</td>
                                <td class="text-left font-bold">Service Office</td>
                                <td class="text-left font-bold">Updated</td>
                            </tr>
                            <tr v-for="(item, i) in report_users.list" :key="i">
                                <td>{{ item.Email }}</td>
                                <td>{{ item.Name }}</td>
                                <td>{{ $globalHelpers.humanize(designations.find(x => x.id === item.DesignationId).label) }}</td>
                                <td>{{ item.OrganizationName }}</td>
                                <td>{{ item.Username }}</td>
                                <td>{{ item.Mobile }}</td>
                                <td>{{ $globalHelpers.humanize(exporterTypes.find(x => x.id === item.ExporterType).label) }}</td>
                                <td>{{ $globalHelpers.humanize(roles.find(x => x.id === item.RoleId).label) }}</td>
                                <td>{{ $globalHelpers.humanize(serviceOffices.find(x => x.id === item.ServiceOfficeId).label)  }}</td>
                                <td>{{ $globalHelpers.getFormattedDate(item.Modified, '', 'DD/MM/YYYY') }}</td>
                            </tr>
                            <tr>
                                <th colspan="10"></th>
                            </tr>
                            <tr>
                                <td>Total:</td>
                                <td>{{ report_users.count }}</td>
                                <td colspan="8"></td>
                            </tr>
                            </tbody>
                        </table>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Vue from "vue";
import ExportReport from "@/components/report/ExportReport";

export default {
    name: "Reports_view",
    components: {ExportReport},
    data: () => ({
        loading: false,

        currentView: 'list',
        applicationTypes: [],
        statuses: [],
        serviceOffices: [],
        roles: [],
        exporterTypes: [],
        designations: [],
        factoryTypes: [],

        report_applications: {
            reportName: 'Applications Report',
            startDate: null,
            endDate: null,
            applicationTypeIds: [],
            statusIds: [],
            serviceOfficeIds: [],
            exporterTypeId: null,
            factoryTypeId: null,
            list: [],
            count: 0,
        },
        report_application_datas: {
            exporterTypes: [],
            factoryTypes: [],
        },
        report_users: {
            reportName: 'Users Report',
            type: null,
            serviceOfficeIds: [],
            roleIds: [],
            exporterTypeIds: [],
            designationIds: [],
            list: [],
            count: 0,
            roles: [],
        },
        report_user_datas: {
            exporterTypes: [],
            designations: [],
            userTypes: [
                {id: 0, label: 'EPB Users'},
                {id: 2, label: 'Exporter Users'},
            ],
        },
    }),
    methods:{
        toggleSelection: function(field){
            if(field === 'report_applications.applicationTypeIds'){
                this.$nextTick(() => {
                    if (this.report_applications.applicationTypeIds.length === this.applicationTypes.length) {
                        this.report_applications.applicationTypeIds = []
                    } else {
                        this.report_applications.applicationTypeIds = this.$globalHelpers.pluck(this.applicationTypes, 'id');
                    }
                })
            }
            if(field === 'report_applications.statusIds'){
                this.$nextTick(() => {
                    if (this.report_applications.statusIds.length === this.statuses.length) {
                        this.report_applications.statusIds = []
                    } else {
                        this.report_applications.statusIds = this.$globalHelpers.pluck(this.statuses, 'id');
                    }
                })
            }
            if(field === 'report_applications.serviceOfficeIds'){
                this.$nextTick(() => {
                    if (this.report_applications.serviceOfficeIds.length === this.serviceOffices.length) {
                        this.report_applications.serviceOfficeIds = []
                    } else {
                        this.report_applications.serviceOfficeIds = this.$globalHelpers.pluck(this.serviceOffices, 'id');
                    }
                })
            }
            /*if(field === 'report_applications.exporterTypeIds'){
                this.$nextTick(() => {
                    if (this.report_applications.exporterTypeIds.length === this.exporterTypes.length) {
                        this.report_applications.exporterTypeIds = []
                    } else {
                        this.report_applications.exporterTypeIds = this.$globalHelpers.pluck(this.exporterTypes, 'id');
                    }
                })
            }*/
            if(field === 'report_applications.factoryTypeIds'){
                this.$nextTick(() => {
                    if (this.report_applications.factoryTypeIds.length === this.factoryTypes.length) {
                        this.report_applications.factoryTypeIds = []
                    } else {
                        this.report_applications.factoryTypeIds = this.$globalHelpers.pluck(this.factoryTypes, 'id');
                    }
                })
            }
            if(field === 'report_users.serviceOfficeIds'){
                this.$nextTick(() => {
                    if (this.report_users.serviceOfficeIds.length === this.serviceOffices.length) {
                        this.report_users.serviceOfficeIds = []
                    } else {
                        this.report_users.serviceOfficeIds = this.$globalHelpers.pluck(this.serviceOffices, 'id');
                    }
                })
            }
            if(field === 'report_users.roleIds'){
                this.$nextTick(() => {
                    if (this.report_users.roleIds.length === this.report_users.roles.length) {
                        this.report_users.roleIds = []
                    } else {
                        this.report_users.roleIds = this.$globalHelpers.pluck(this.report_users.roles, 'id');
                    }
                })
            }
            if(field === 'report_users.exporterTypeIds'){
                this.$nextTick(() => {
                    if (this.report_users.exporterTypeIds.length === this.report_user_datas.exporterTypes.length) {
                        this.report_users.exporterTypeIds = []
                    } else {
                        this.report_users.exporterTypeIds = this.$globalHelpers.pluck(this.report_user_datas.exporterTypes, 'id');
                    }
                })
            }
            if(field === 'report_users.designationIds'){
                this.$nextTick(() => {
                    if (this.report_users.designationIds.length === this.report_user_datas.designations.length) {
                        this.report_users.designationIds = []
                    } else {
                        this.report_users.designationIds = this.$globalHelpers.pluck(this.report_user_datas.designations, 'id');
                    }
                })
            }
        },
        reportApplicationsModifyFilters: function (){
            console.log('modify');
            this.report_application_datas.factoryTypes = [];
            this.report_application_datas.factoryTypes = this.factoryTypes.filter(x => x.Type === this.report_applications.exporterTypeId);
        },
        reportUsersModifyFilters: function (){
            console.log('modify');
            this.report_users.roleIds = [];
            this.report_users.exporterTypeIds = [];
            this.report_users.designationIds = [];
            if(this.report_users.type === 2){
                this.report_users.roles = this.roles.filter(x => x.id === this.$globalEnums.roles.Exporter);
                this.report_user_datas.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(x => x.id === 1 || x.id === 2);
                this.report_user_datas.designations = this.$globalHelpers.enumToArray(this.$globalEnums.Designations).filter(x => x.id === null || (x.id >= 100 && x.id <= 103));
            }else{
                this.report_users.roles = this.roles.filter(x => x.id !== this.$globalEnums.roles.Exporter);
                this.report_user_datas.exporterTypes = this.exporterTypes;
                this.report_user_datas.designations = this.$globalHelpers.enumToArray(this.$globalEnums.Designations).filter(x => x.id <= 50);
            }
        },
        generate: async function (report_key) {
            this.currentView = report_key;
            this.loading = true;
            if (report_key === 'report_applications') {
                this.report_applications.list = [];
                await this.$axios.post(this.$globalSettings.api.endpoints.report.applications, this.report_applications).then(async (response) => {
                    this.loading = false;
                    if (response.data.Status === 'success') {
                        this.report_applications.list = response.data.List;
                        this.report_applications.count = response.data.Count;
                        Vue.$toast.open({message: "Report Generated.", type: response.data.Status});
                    }else{
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.report_applications.list = [];
                    this.$globalHelpers.processHttpErrorsToast(error, true);
                });
            }
            if (report_key === 'report_users') {
                this.report_users.list = [];
                await this.$axios.post(this.$globalSettings.api.endpoints.report.users, this.report_users).then(async (response) => {
                    this.loading = false;
                    if (response.data.Status === 'success') {
                        this.report_users.list = response.data.List;
                        this.report_users.count = response.data.Count;
                        Vue.$toast.open({message: "Report Generated.", type: response.data.Status});
                    }else{
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.report_users.list = [];
                    this.$globalHelpers.processHttpErrorsToast(error, true);
                });
            }
        },
        showReportsList: function (){
            this.currentView = 'list';
        },
        navigationBack: async function () {
            this.$router.go(-1);
        },
    },
    async created() {
        this.report_applications.startDate = this.$globalHelpers.getDateToday('YYYY-MM-DD');
        this.report_applications.endDate = this.$globalHelpers.getDateToday('YYYY-MM-DD');

        this.applicationTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ApplicationTypes);
        this.statuses = this.$globalHelpers.enumToArray(this.$globalEnums.ApplicationStatuses);
        this.serviceOffices = this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices);
        this.roles = this.$globalHelpers.enumToArray(this.$globalEnums.roles);
        this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes);
        /*this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(function(x){
            return x.id === 1 || x.id === 2;
        });*/
        this.report_application_datas.exporterTypes = this.exporterTypes.filter(function(x){
            return x.id === 1 || x.id === 2;
        });
        //this.report_application_datas.exporterTypes.unshift({id: null, label: 'All'});
        this.designations = this.$globalHelpers.enumToArray(this.$globalEnums.Designations);

        this.loading = true;
        await this.$axios.post(this.$globalSettings.api.endpoints.factoryType.list, {
            Label: null,
            Type: null,
            IsAscendingOrder: true,
            Skip: null,
            Take: null,
        })
            .then((response) => {
                this.factoryTypes = response.data.List;
                this.factoryTypes.unshift({Id: null, Label: 'All', Type: 1}, {Id: null, Label: 'All', Type: 2});
                this.loading = false;
            })
            .catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            })
    }
}
</script>

<style scoped>

</style>