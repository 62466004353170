<template>
    <button type="button" class="btn btn-sm btn-outline-secondary" v-on:click="exportToXLSX()"><i class="fas fa-file-excel"></i> Export</button>
</template>

<script>
    import XLSX from 'xlsx';
    export default {
        name: "ExportReport",
        data: function() {
            return {}
        },
        props: {
            reportName: {
                type: String,
                default: ()=> ''
            },
            targetElement: {
                type: String,
                default: ()=> ''
            },
        },
        methods: {
            exportToXLSX: function () {
                let filename = this.reportName+" report.xlsx";
                let ws_name = "Sheet1";
                var elt = document.getElementById(this.targetElement);
                let wb = XLSX.utils.table_to_book(elt, { sheet: ws_name });
                XLSX.writeFile(wb, filename);
            }
        },
    }
</script>

<style scoped>

</style>